var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-3" }, [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c(
        "div",
        { staticClass: "col-lg-4" },
        [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "creditAndCostCalculator.creditCalculator.numberofRespondentsPerYear.label"
                          )
                        ) +
                        ": "
                    ),
                  ]),
                  _c("b-form-input", {
                    style: [
                      _vm.displayROI
                        ? { background: "#f5f5f5" }
                        : { background: "#fff" },
                    ],
                    attrs: {
                      type: "number",
                      placeholder: _vm.$t(
                        "creditAndCostCalculator.creditCalculator.numberofRespondentsPerYear.placeholder"
                      ),
                      disabled: _vm.displayROI,
                    },
                    model: {
                      value: _vm.numberOfRespondentsPerYear,
                      callback: function ($$v) {
                        _vm.numberOfRespondentsPerYear = _vm._n($$v)
                      },
                      expression: "numberOfRespondentsPerYear",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "mt-4" }, [
                _c("label", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "creditAndCostCalculator.creditCalculator.qualityChecks.title"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "row align-items-center mt-2 ml-2" }, [
                  _c("div", { staticClass: "col-1" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.qualityScores.TS.isEnable,
                          expression: "qualityScores.TS.isEnable",
                        },
                      ],
                      staticClass: "text-primary",
                      attrs: { type: "checkbox", disabled: _vm.displayROI },
                      domProps: {
                        checked: Array.isArray(_vm.qualityScores.TS.isEnable)
                          ? _vm._i(_vm.qualityScores.TS.isEnable, null) > -1
                          : _vm.qualityScores.TS.isEnable,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.qualityScores.TS.isEnable,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.qualityScores.TS,
                                  "isEnable",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.qualityScores.TS,
                                  "isEnable",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.qualityScores.TS, "isEnable", $$c)
                          }
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "col-11" }, [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "creditAndCostCalculator.creditCalculator.qualityChecks.types.timestamps"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row align-items-center mt-2 ml-2" }, [
                  _c("div", { staticClass: "col-1" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.qualityScores.OES.isEnable,
                          expression: "qualityScores.OES.isEnable",
                        },
                      ],
                      staticClass: "text-primary",
                      attrs: { type: "checkbox", disabled: _vm.displayROI },
                      domProps: {
                        checked: Array.isArray(_vm.qualityScores.OES.isEnable)
                          ? _vm._i(_vm.qualityScores.OES.isEnable, null) > -1
                          : _vm.qualityScores.OES.isEnable,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.qualityScores.OES.isEnable,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.qualityScores.OES,
                                  "isEnable",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.qualityScores.OES,
                                  "isEnable",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.qualityScores.OES, "isEnable", $$c)
                          }
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "col-7" }, [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "creditAndCostCalculator.creditCalculator.qualityChecks.types.openEnded"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("b-form-input", {
                        style: [
                          _vm.displayROI
                            ? { background: "#f5f5f5" }
                            : { background: "#fff" },
                        ],
                        attrs: {
                          type: "number",
                          placeholder: _vm.$t(
                            "creditAndCostCalculator.creditCalculator.qualityChecks.placeholderForNumberOfChecks"
                          ),
                          disabled: _vm.displayROI,
                        },
                        model: {
                          value: _vm.qualityScores.OES.qty,
                          callback: function ($$v) {
                            _vm.$set(_vm.qualityScores.OES, "qty", $$v)
                          },
                          expression: "qualityScores.OES.qty",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row align-items-center mt-2 ml-2" }, [
                  _c("div", { staticClass: "col-1" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.qualityScores.IBS.isEnable,
                          expression: "qualityScores.IBS.isEnable",
                        },
                      ],
                      staticClass: "text-primary",
                      attrs: { type: "checkbox", disabled: _vm.displayROI },
                      domProps: {
                        checked: Array.isArray(_vm.qualityScores.IBS.isEnable)
                          ? _vm._i(_vm.qualityScores.IBS.isEnable, null) > -1
                          : _vm.qualityScores.IBS.isEnable,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.qualityScores.IBS.isEnable,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.qualityScores.IBS,
                                  "isEnable",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.qualityScores.IBS,
                                  "isEnable",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.qualityScores.IBS, "isEnable", $$c)
                          }
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "col-7" }, [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "creditAndCostCalculator.creditCalculator.qualityChecks.types.itemBatteries"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("b-form-input", {
                        style: [
                          _vm.displayROI
                            ? { background: "#f5f5f5" }
                            : { background: "#fff" },
                        ],
                        attrs: {
                          type: "number",
                          placeholder: _vm.$t(
                            "creditAndCostCalculator.creditCalculator.qualityChecks.placeholderForNumberOfChecks"
                          ),
                          disabled: _vm.displayROI,
                        },
                        model: {
                          value: _vm.qualityScores.IBS.qty,
                          callback: function ($$v) {
                            _vm.$set(_vm.qualityScores.IBS, "qty", $$v)
                          },
                          expression: "qualityScores.IBS.qty",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row align-items-center mt-2 ml-2" }, [
                  _c("div", { staticClass: "col-1" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.qualityScores.PQ.isEnable,
                          expression: "qualityScores.PQ.isEnable",
                        },
                      ],
                      staticClass: "text-primary",
                      attrs: { type: "checkbox", disabled: _vm.displayROI },
                      domProps: {
                        checked: Array.isArray(_vm.qualityScores.PQ.isEnable)
                          ? _vm._i(_vm.qualityScores.PQ.isEnable, null) > -1
                          : _vm.qualityScores.PQ.isEnable,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.qualityScores.PQ.isEnable,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.qualityScores.PQ,
                                  "isEnable",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.qualityScores.PQ,
                                  "isEnable",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.qualityScores.PQ, "isEnable", $$c)
                          }
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "col-7" }, [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "creditAndCostCalculator.creditCalculator.qualityChecks.types.projectiveQuestions"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _vm._m(0),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("b-form-input", {
                        style: [
                          _vm.displayROI
                            ? { background: "#f5f5f5" }
                            : { background: "#fff" },
                        ],
                        attrs: {
                          type: "number",
                          placeholder: _vm.$t(
                            "creditAndCostCalculator.creditCalculator.qualityChecks.placeholderForNumberOfChecks"
                          ),
                          disabled: _vm.displayROI,
                        },
                        model: {
                          value: _vm.qualityScores.PQ.qty,
                          callback: function ($$v) {
                            _vm.$set(_vm.qualityScores.PQ, "qty", $$v)
                          },
                          expression: "qualityScores.PQ.qty",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm.displayQualityCertificate
                ? _c("div", { staticClass: "row mt-4" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "creditAndCostCalculator.creditCalculator.qualityChecks.certificateLevel"
                              )
                            ) +
                            ": "
                        ),
                        _c(
                          "span",
                          { staticClass: "ml-2" },
                          [
                            _c(
                              "b-badge",
                              {
                                staticStyle: { "font-size": "12px" },
                                attrs: { variant: "gold" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(this.availableQualityStandard) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "mt-4" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-8" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "creditAndCostCalculator.creditCalculator.maximumCreditPerRespondent.label"
                          )
                        ) +
                        " "
                    ),
                    _c("span", [
                      _c("i", {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover",
                            value: _vm.propoverMethodMaximumCreditPerRespondent,
                            expression:
                              "\n                      propoverMethodMaximumCreditPerRespondent\n                    ",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "ml-2 bx bxs-info-circle text-info",
                        staticStyle: { cursor: "pointer" },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.creditPerRespondent.toLocaleString("de-DE")
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "row mt-2" }, [
                  _c("div", { staticClass: "col-8" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "creditAndCostCalculator.creditCalculator.maximumNumberOfCreditsPerYear"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "col-4 text-right" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.totalCreditAmount.toLocaleString("de-DE")) +
                        " "
                    ),
                  ]),
                ]),
                _vm.displayCreditAmoutNotSufficientError
                  ? _c("div", { staticClass: "mt-4" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c(
                              "b-alert",
                              {
                                staticClass: "mt-3",
                                attrs: { variant: "danger" },
                                model: {
                                  value:
                                    _vm.displayCreditAmoutNotSufficientError,
                                  callback: function ($$v) {
                                    _vm.displayCreditAmoutNotSufficientError =
                                      $$v
                                  },
                                  expression:
                                    "displayCreditAmoutNotSufficientError",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "creditAndCostCalculator.creditCalculator.lowCreditAmount"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.displayCreditAmoutNotSufficientError
                  ? _c("div", { staticClass: "row mt-2" }, [
                      _c("div", { staticClass: "col-8" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "creditAndCostCalculator.creditCalculator.pricePerCredit"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "col-4 text-right" }, [
                        _vm._v(
                          " EUR " +
                            _vm._s(
                              _vm.pricePerCredit.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 4,
                              })
                            ) +
                            " "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.displayCreditAmoutNotSufficientError ? _c("hr") : _vm._e(),
                !_vm.displayCreditAmoutNotSufficientError
                  ? _c("div", { staticClass: "row mt-2" }, [
                      _c("div", { staticClass: "col-8" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "creditAndCostCalculator.creditCalculator.pricePerRespondent"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "col-4 text-right" }, [
                        _vm._v(
                          " EUR " +
                            _vm._s(
                              _vm.pricePerRespondent.toLocaleString("de-DE", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            ) +
                            " "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.displayCreditAmoutNotSufficientError
                  ? _c("div", { staticClass: "row mt-2" }, [
                      _c("div", { staticClass: "col-8" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "creditAndCostCalculator.creditCalculator.totalCostPerYear"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "col-4 text-right" }, [
                        _vm._v(
                          " EUR " +
                            _vm._s(
                              _vm.totalRedemCostPerYear.toLocaleString(
                                "de-DE",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.displayYourSituation &&
                    !_vm.displayCreditAmoutNotSufficientError,
                  expression:
                    "\n          !displayYourSituation && !displayCreditAmoutNotSufficientError\n        ",
                },
              ],
              staticClass: "btn-block p-2",
              attrs: { variant: "success" },
              on: {
                click: function ($event) {
                  return _vm.clickDisplayYourSituation()
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "creditAndCostCalculator.creditCalculator.continueButton"
                    )
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _vm.displayYourSituation
      ? _c("div", { staticClass: "row justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-lg-4" },
            [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "card-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "creditAndCostCalculator.currentSituation.title"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "mt-3 row align-items-center" }, [
                    _c("div", { staticClass: "col-9" }, [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "creditAndCostCalculator.currentSituation.numberofProjectsPerYear.label"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("b-form-input", {
                          style: [
                            _vm.displayROI
                              ? { background: "#f5f5f5" }
                              : { background: "#fff" },
                          ],
                          attrs: {
                            type: "number",
                            placeholder: _vm.$t(
                              "creditAndCostCalculator.currentSituation.numberofProjectsPerYear.placeholder"
                            ),
                            disabled: _vm.displayROI,
                          },
                          model: {
                            value: _vm.numberOfProjectsPerYear,
                            callback: function ($$v) {
                              _vm.numberOfProjectsPerYear = _vm._n($$v)
                            },
                            expression: "numberOfProjectsPerYear",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "mt-3 row align-items-center" }, [
                    _c("div", { staticClass: "col-9" }, [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "creditAndCostCalculator.currentSituation.timeToSpent.label"
                              )
                            ) +
                            " "
                        ),
                        _c("span", [
                          _c("i", {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover",
                                value: _vm.propoverMethodTimeSpentForEvaluation,
                                expression:
                                  "propoverMethodTimeSpentForEvaluation",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "ml-1 bx bxs-info-circle text-info",
                            staticStyle: { cursor: "pointer" },
                          }),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("b-form-input", {
                          style: [
                            _vm.displayROI
                              ? { background: "#f5f5f5" }
                              : { background: "#fff" },
                          ],
                          attrs: {
                            type: "number",
                            placeholder: _vm.$t(
                              "creditAndCostCalculator.currentSituation.timeToSpent.placeholder"
                            ),
                            disabled: _vm.displayROI,
                          },
                          model: {
                            value: _vm.numberOfHoursSpentPerProject,
                            callback: function ($$v) {
                              _vm.numberOfHoursSpentPerProject = _vm._n($$v)
                            },
                            expression: "numberOfHoursSpentPerProject",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "mt-3 row align-items-center" }, [
                    _c("div", { staticClass: "col-9" }, [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "creditAndCostCalculator.currentSituation.costPerHour.label"
                              )
                            ) +
                            " "
                        ),
                        _c("span", [
                          _c("i", {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover",
                                value: _vm.propoverMethodEstimatedCostPerHour,
                                expression:
                                  "propoverMethodEstimatedCostPerHour",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "ml-1 bx bxs-info-circle text-info",
                            staticStyle: { cursor: "pointer" },
                          }),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("b-form-input", {
                          style: [
                            _vm.displayROI
                              ? { background: "#f5f5f5" }
                              : { background: "#fff" },
                          ],
                          attrs: {
                            type: "number",
                            placeholder: _vm.$t(
                              "creditAndCostCalculator.currentSituation.costPerHour.placeholder"
                            ),
                            disabled: _vm.displayROI,
                          },
                          model: {
                            value: _vm.costPerHour,
                            callback: function ($$v) {
                              _vm.costPerHour = _vm._n($$v)
                            },
                            expression: "costPerHour",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.displayROI,
                      expression: "!displayROI",
                    },
                  ],
                  staticClass: "btn-block p-2",
                  attrs: {
                    variant: "success",
                    disabled:
                      !_vm.numberOfProjectsPerYear ||
                      !_vm.numberOfHoursSpentPerProject ||
                      !_vm.costPerHour,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.clickDisplayROI()
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "creditAndCostCalculator.currentSituation.calculateCostButton"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row justify-content-center" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.displayROI,
              expression: "displayROI",
            },
          ],
          staticClass: "col-lg-4",
        },
        [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "card-title" }, [
                _c("div", { staticClass: "row mb-3 align-content-center" }, [
                  _c("div", { staticClass: "col-7" }, [
                    _c("h5", [
                      _c("i", {
                        staticClass: "bx bx-money h4 text-warning bx-tada mr-1",
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "creditAndCostCalculator.costSaveSummary.title"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-5 text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "dark", size: "sm" },
                          on: {
                            click: function ($event) {
                              return _vm.clickHideROI()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "creditAndCostCalculator.costSaveSummary.editButton"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "creditAndCostCalculator.costSaveSummary.description"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("table", { staticClass: "table mt-3 mb-5" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "creditAndCostCalculator.costSaveSummary.table.projectCost"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "creditAndCostCalculator.costSaveSummary.table.yearlyCost"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("label", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "creditAndCostCalculator.costSaveSummary.table.currentSituation"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.internalCostPerProject.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.totalInternalCostPerYear.toLocaleString(
                              "de-DE",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _vm._m(1),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.redemCostPerProject.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.totalRedemCostPerYear.toLocaleString("de-DE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", [
                      _c(
                        "label",
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "creditAndCostCalculator.costSaveSummary.table.costsaving"
                                )
                              ) +
                              " "
                          ),
                          _vm.costSavingPerYearPercentage > 0
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "ml-2 badge-soft-success",
                                  staticStyle: { "font-size": "15px" },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.costSavingPerYearPercentage.toLocaleString(
                                            "de-DE",
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                            }
                                          )
                                        ) +
                                        " % "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c("td", { staticClass: "text-right" }, [
                      _vm.costSavingPerProject > 0
                        ? _c("strong", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.costSavingPerProject.toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" 0 ")]),
                    ]),
                    _c("td", { staticClass: "text-right" }, [
                      _vm.costSavingPerYear > 0
                        ? _c("strong", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.costSavingPerYear.toLocaleString(
                                    "de-DE",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" 0 ")]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "card-title mt-5" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("h5", [
                      _c("i", {
                        staticClass:
                          "bx bx-diamond h4 text-success bx-tada mr-1",
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "creditAndCostCalculator.costSaveSummary.benefits.title"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("ul", [
                    _c("li", { staticClass: "mb-2" }, [
                      _c("strong", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "creditAndCostCalculator.costSaveSummary.benefits.point1.bold"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "creditAndCostCalculator.costSaveSummary.benefits.point1.normal"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("li", { staticClass: "mb-2" }, [
                      _c("strong", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "creditAndCostCalculator.costSaveSummary.benefits.point2.bold"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "creditAndCostCalculator.costSaveSummary.benefits.point2.normal"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("li", { staticClass: "mb-2" }, [
                      _c("strong", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "creditAndCostCalculator.costSaveSummary.benefits.point3.bold"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "creditAndCostCalculator.costSaveSummary.benefits.point3.normal"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.displayROI,
                  expression: "displayROI",
                },
              ],
              staticClass: "btn-block p-2",
              attrs: { variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.clickMailToButton()
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("creditAndCostCalculator.requestOfferButton")) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row justify-content-center text-center mt-3" }, [
      _c("div", { staticClass: "col-md-4" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("creditAndCostCalculator.legalSentence")) + " "
        ),
      ]),
    ]),
    _c("div", { staticClass: "mt-3 text-center" }, [
      _c(
        "span",
        {
          staticClass: "mr-2",
          staticStyle: { cursor: "pointer" },
          on: {
            click: function ($event) {
              return _vm.setLanguage(_vm.languages[0].language)
            },
          },
        },
        [
          _c("img", {
            staticClass: "mr-1",
            attrs: { src: _vm.languages[0].flag, height: "10" },
          }),
          _vm._v(" " + _vm._s(_vm.languages[0].title) + " "),
        ]
      ),
      _vm._v(" • "),
      _c(
        "span",
        {
          staticClass: "ml-2",
          staticStyle: { cursor: "pointer" },
          on: {
            click: function ($event) {
              return _vm.setLanguage(_vm.languages[1].language)
            },
          },
        },
        [
          _c("img", {
            staticClass: "mr-1",
            attrs: { src: _vm.languages[1].flag, height: "10" },
          }),
          _vm._v(" " + _vm._s(_vm.languages[1].title) + " "),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c(
        "a",
        {
          attrs: {
            href: "https://help.redem.io/docs/items-qualtiy-checks-questionnaire/projective-questions/general-info-projective-questions/",
            target: "_blank",
          },
        },
        [
          _c("i", {
            staticClass: "ml-2 bx bxs-info-circle text-info",
            staticStyle: { cursor: "pointer" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("span", { staticClass: "logo-lg" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/Redem-Logo.png"),
            alt: "",
            height: "25",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }