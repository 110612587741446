<script>
import QualityScoresService from "@/api/services/qualitySocreInfo.service";
import appConfig from "@/app.config";
import i18n from "@/i18n";
import {
MAXIMUM_NUMBER_OF_CREDITS_FOR_IBS,
MAXIMUM_NUMBER_OF_CREDITS_PER_RESPONDENT,
MINIMU_NUMBER_OF_CREDITS_PER_RESPONDENT
} from "../util/const.js";

export default {
  page: {
    title: "Credit & Cost Calculator",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {},
  data() {
    return {
      /****** Credit Calculator Variable *****/
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/germany.jpg"),
          language: "de",
          title: "Deutsch"
        }
      ],
      lan: i18n.locale,
      qualityScoreInfo: null,
      allCertificateLevels: [
        { name: "Standard", qualityScores: { OES: 2, IBS: 0, TS: 1, PQ: 0 } },
        {
          name: "Standard Plus",
          qualityScores: { OES: 2, IBS: 7, TS: 1, PQ: 0 }
        },
        { name: "Extended", qualityScores: { OES: 2, IBS: 15, TS: 1, PQ: 1 } }
      ],
      qualityScores: {
        OES: { isEnable: true, qty: 2 },
        IBS: { isEnable: true, qty: 15 },
        TS: { isEnable: true, qty: 1 },
        PQ: { isEnable: false, qty: null }
      },
      numberOfRespondentsPerYear: 50000,
      numberOfProjectsPerYear: null,
      totalCreditAmount: 0,
      totalRedemCostPerYear: 0,
      pricePerCredit: 0,
      creditPerRespondent: 0,
      pricePerRespondent: 0,
      displayROI: false,
      displayYourSituation: false,
      displayQualityCertificate: false,
      displayCreditAmoutNotSufficientError: false,
      availableQualityStandard: "Standard",
      /****** ROI Calculator Variable *****/
      numberOfHoursSpentPerProject: 5,
      costPerHour: 60,
      totalInternalCostPerYear: 0,
      internalCostPerProject: 0,
      redemCostPerProject: 0,
      costSavingPerYear: 0,
      costSavingPerYearPercentage: 0,
      costSavingPerProject: 0,
      /****** Const ******/
      numberOfRespondentsForAverageProject: 1000
    };
  },
  watch: {
    qualityScores: {
      handler() {
        this.calculation();
      },
      deep: true
    },
    numberOfRespondentsPerYear() {
      this.calculation();
    }
  },
  computed: {
    propoverMethodMaximumCreditPerRespondent() {
      return {
        html: true,
        content: () =>
          "<ul> <li>" +
          this.$i18n.t(
            "creditAndCostCalculator.creditCalculator.maximumCreditPerRespondent.description.point1"
          ) +
          "</li> <br/> <li>" +
          this.$i18n.t(
            "creditAndCostCalculator.creditCalculator.maximumCreditPerRespondent.description.point2"
          ) +
          "</li> <br/> <li>" +
          "<a href='https://help.redem.io/docs/data-import/redem-credits/' target='_blank'>" +
          this.$i18n.t(
            "creditAndCostCalculator.creditCalculator.maximumCreditPerRespondent.description.moreInfo"
          ) +
          "</a>" +
          "</li> </ul>"
      };
    },
    propoverMethodTimeSpentForEvaluation() {
      return {
        html: true,
        content: () =>
          this.$i18n.t(
            "creditAndCostCalculator.currentSituation.timeToSpent.description"
          )
      };
    },
    propoverMethodEstimatedCostPerHour() {
      return {
        html: true,
        content: () =>
          this.$i18n.t(
            "creditAndCostCalculator.currentSituation.costPerHour.description"
          )
      };
    }
  },
  async mounted() {
    await this.getQualityScoreInfo();
    await this.calculation();
  },
  methods: {
    async getQualityScoreInfo() {
      try {
        this.qualityScoreInfo =
          await QualityScoresService.getAllQualityScoreInfo();
      } catch (e) {
        console.log("Fetching Quality Scores Having an Error", e);
      }
    },
    async calculation() {
      // 1. Set value to certificate level
      await this.setValueToCertificateLevel();

      // 2. Set value to creditPerRespondent
      await this.setValueToCreditPerRespondent();

      // 3. set value to totalCreditAmount
      await this.setValueToTotalCreditAmount();

      // 4. Set value to pricePerCredit
      await this.setValueToPricePerCredit();

      // 5. Set value to pricePerRespondent
      await this.setPricePerRespondent();

      // 6. Set value to totalRedemCostPerYear
      await this.setValueToCostPerYear();

      // 7. Set value to InternalCosts
      await this.setValuesToInternalCosts();

      // 8. set values to RedemCosts
      await this.setValuesToRedemCosts();

      // 9. set values to cost savings
      await this.setValuesToCostSaving();
    },
    setValueToTotalCreditAmount() {
      this.totalCreditAmount =
        this.numberOfRespondentsPerYear * this.creditPerRespondent;
    },
    setValueToPricePerCredit() {
      this.displayCreditAmoutNotSufficientError = false;

      if (this.totalCreditAmount < 50000)
        this.displayCreditAmoutNotSufficientError = true;
      if (this.totalCreditAmount >= 50000 && this.totalCreditAmount < 200000)
        this.pricePerCredit = 0.04;
      if (this.totalCreditAmount >= 200000 && this.totalCreditAmount < 300000)
        this.pricePerCredit = 0.03;
      if (this.totalCreditAmount >= 300000 && this.totalCreditAmount < 500000)
        this.pricePerCredit = 0.025;
      if (this.totalCreditAmount >= 500000 && this.totalCreditAmount < 800000)
        this.pricePerCredit = 0.02;
      if (this.totalCreditAmount >= 800000 && this.totalCreditAmount < 1200000)
        this.pricePerCredit = 0.0175;
      if (this.totalCreditAmount >= 1200000 && this.totalCreditAmount < 2000000)
        this.pricePerCredit = 0.015;
      if (this.totalCreditAmount >= 2000000 && this.totalCreditAmount < 3000000)
        this.pricePerCredit = 0.0125;
      if (this.totalCreditAmount >= 3000000) this.pricePerCredit = 0.01;
    },
    setValueToCreditPerRespondent() {
      this.creditPerRespondent = 0;
      let qualityScorePrices = {
        TS: 0,
        OES: 0,
        IBS: 0,
        PQ: 0
      };

      for (let i = 0; i < this.qualityScoreInfo.length; i++) {
        // Calculate Credti For TS
        if (
          this.qualityScoreInfo[i].code === "TS" &&
          this.qualityScores.TS.isEnable
        ) {
          qualityScorePrices.TS += this.qualityScoreInfo[i].creditPrice;
        }

        // Calculate Credti For IBS
        if (
          this.qualityScoreInfo[i].code === "IBS" &&
          this.qualityScores.IBS.isEnable &&
          this.qualityScores.IBS.qty > 0
        ) {
          qualityScorePrices.IBS +=
            this.qualityScores.IBS.qty * this.qualityScoreInfo[i].creditPrice;

          if (qualityScorePrices.IBS > MAXIMUM_NUMBER_OF_CREDITS_FOR_IBS)
            qualityScorePrices.IBS = MAXIMUM_NUMBER_OF_CREDITS_FOR_IBS;
        }

        // Calculate Credti For OES
        if (
          this.qualityScoreInfo[i].code === "OES" &&
          this.qualityScores.OES.isEnable &&
          this.qualityScores.OES.qty > 0
        ) {
          let OESCount = this.qualityScores.OES.qty;
          // count credit for first OES Data point
          qualityScorePrices.OES += this.qualityScoreInfo[i].creditPrice;
          OESCount -= 1;

          // count credit for other OES Data point
          qualityScorePrices.OES +=
            OESCount *
            this.qualityScoreInfo[i].creditPriceForAdditionalDataPoint;
        }

        // Calculate Credti For PQ
        if (
          this.qualityScoreInfo[i].code === "PQ" &&
          this.qualityScores.PQ.isEnable &&
          this.qualityScores.PQ.qty > 0
        ) {
          let PQCount = this.qualityScores.PQ.qty;
          // count credit for first OES Data point
          qualityScorePrices.PQ += this.qualityScoreInfo[i].creditPrice;
          PQCount -= 1;

          // count credit for other OES Data point
          qualityScorePrices.PQ +=
            PQCount *
            this.qualityScoreInfo[i].creditPriceForAdditionalDataPoint;
        }
      }

      // calculcate final credit price per respondent
      this.creditPerRespondent =
        qualityScorePrices.TS +
        qualityScorePrices.IBS +
        qualityScorePrices.OES +
        qualityScorePrices.PQ;

      if (this.creditPerRespondent > MAXIMUM_NUMBER_OF_CREDITS_PER_RESPONDENT)
        this.creditPerRespondent = MAXIMUM_NUMBER_OF_CREDITS_PER_RESPONDENT;
      if (this.creditPerRespondent < MINIMU_NUMBER_OF_CREDITS_PER_RESPONDENT)
        this.creditPerRespondent = MINIMU_NUMBER_OF_CREDITS_PER_RESPONDENT;
    },
    setValueToCostPerYear() {
      this.totalRedemCostPerYear =
        this.pricePerRespondent * this.numberOfRespondentsPerYear;
    },
    setPricePerRespondent() {
      this.pricePerRespondent = this.creditPerRespondent * this.pricePerCredit;
    },
    setValuesToInternalCosts() {
      // calculate totalInternCostPerYear
      this.totalInternalCostPerYear =
        this.numberOfProjectsPerYear *
        this.numberOfHoursSpentPerProject *
        this.costPerHour;

      // calculate internalCostPerProject
      this.internalCostPerProject =
        this.numberOfHoursSpentPerProject * this.costPerHour;
    },
    setValuesToRedemCosts() {
      this.redemCostPerProject =
        this.totalRedemCostPerYear / this.numberOfProjectsPerYear;
    },
    setValuesToCostSaving() {
      // calculate costSavingPerYear
      this.costSavingPerYear =
        this.totalInternalCostPerYear - this.totalRedemCostPerYear;

      // calculate costSavingPerYearPercentage
      this.costSavingPerYearPercentage =
        (this.costSavingPerYear / this.totalInternalCostPerYear) * 100;

      // calculate costSavingPerProject
      this.costSavingPerProject =
        this.internalCostPerProject - this.redemCostPerProject;
    },
    async clickDisplayROI() {
      await this.calculation();
      this.displayROI = true;
    },
    clickHideROI() {
      this.displayROI = false;
    },
    clickDisplayYourSituation() {
      this.displayYourSituation = true;
    },
    setLanguage(locale) {
      this.lan = locale;
      i18n.locale = locale;
      window.localStorage.setItem("locale", locale);
    },
    setValueToCertificateLevel() {
      this.displayQualityCertificate = false;
      this.availableQualityStandard = null;

      let selectedQualityScores = {
        TS: 0,
        OES: 0,
        IBS: 0,
        PQ: 0
      };

      for (const qualityScore in this.qualityScores) {
        if (qualityScore === "OES" && this.qualityScores[qualityScore].isEnable)
          selectedQualityScores.OES = parseInt(
            this.qualityScores[qualityScore].qty
          );
        if (qualityScore === "TS" && this.qualityScores[qualityScore].isEnable)
          selectedQualityScores.TS = parseInt(
            this.qualityScores[qualityScore].qty
          );
        if (qualityScore === "IBS" && this.qualityScores[qualityScore].isEnable)
          selectedQualityScores.IBS = parseInt(
            this.qualityScores[qualityScore].qty
          );
        if (qualityScore === "PQ" && this.qualityScores[qualityScore].isEnable)
          selectedQualityScores.PQ = parseInt(
            this.qualityScores[qualityScore].qty
          );
      }

      if (
        selectedQualityScores.TS >= 1 &&
        selectedQualityScores.OES >= 2 &&
        selectedQualityScores.IBS > 0 &&
        selectedQualityScores.IBS < 7
      ) {
        // check for Standard Certificate Level
        this.availableQualityStandard = "Standard";
        this.displayQualityCertificate = true;
      }

      if (
        selectedQualityScores.TS >= 1 &&
        selectedQualityScores.OES >= 2 &&
        selectedQualityScores.IBS >= 7
      ) {
        // check for Standard Plus Certificate Level
        this.availableQualityStandard = "Standard Plus";
        this.displayQualityCertificate = true;
      }

      if (
        selectedQualityScores.TS >= 1 &&
        selectedQualityScores.OES >= 2 &&
        selectedQualityScores.IBS >= 15 &&
        selectedQualityScores.PQ >= 1
      ) {
        // check for Extended Certificate Level
        this.availableQualityStandard = "Extended";
        this.displayQualityCertificate = true;
      }
    },
    clickMailToButton() {
      window.location.assign("mailto:credits@redem.io");
    }
  }
};
</script>

<template>
  <div class="p-3">
    <!-- Credit Calculator -->
    <div class="row justify-content-center">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <!-- Number of respondents per year -->
            <div>
              <label>
                {{
                  $t(
                    "creditAndCostCalculator.creditCalculator.numberofRespondentsPerYear.label"
                  )
                }}:
              </label>
              <b-form-input
                v-model.number="numberOfRespondentsPerYear"
                type="number"
                :placeholder="
                  $t(
                    'creditAndCostCalculator.creditCalculator.numberofRespondentsPerYear.placeholder'
                  )
                "
                :disabled="displayROI"
                :style="[
                  displayROI
                    ? { background: '#f5f5f5' }
                    : { background: '#fff' }
                ]"
              >
              </b-form-input>
            </div>

            <!-- Quality Scores -->
            <div class="mt-4">
              <label>
                {{
                  $t(
                    "creditAndCostCalculator.creditCalculator.qualityChecks.title"
                  )
                }}
              </label>
              <!-- TS -->
              <div class="row align-items-center mt-2 ml-2">
                <div class="col-1">
                  <input
                    class="text-primary"
                    type="checkbox"
                    v-model="qualityScores.TS.isEnable"
                    :disabled="displayROI"
                  />
                </div>
                <div class="col-11">
                  <label>
                    {{
                      $t(
                        "creditAndCostCalculator.creditCalculator.qualityChecks.types.timestamps"
                      )
                    }}
                  </label>
                </div>
              </div>
              <!-- OES -->
              <div class="row align-items-center mt-2 ml-2">
                <div class="col-1">
                  <input
                    class="text-primary"
                    type="checkbox"
                    v-model="qualityScores.OES.isEnable"
                    :disabled="displayROI"
                  />
                </div>
                <div class="col-7">
                  <label>
                    {{
                      $t(
                        "creditAndCostCalculator.creditCalculator.qualityChecks.types.openEnded"
                      )
                    }}
                  </label>
                </div>
                <div class="col-4">
                  <b-form-input
                    type="number"
                    :placeholder="
                      $t(
                        'creditAndCostCalculator.creditCalculator.qualityChecks.placeholderForNumberOfChecks'
                      )
                    "
                    v-model="qualityScores.OES.qty"
                    :disabled="displayROI"
                    :style="[
                      displayROI
                        ? { background: '#f5f5f5' }
                        : { background: '#fff' }
                    ]"
                  >
                  </b-form-input>
                </div>
              </div>
              <!-- IBS -->
              <div class="row align-items-center mt-2 ml-2">
                <div class="col-1">
                  <input
                    class="text-primary"
                    type="checkbox"
                    v-model="qualityScores.IBS.isEnable"
                    :disabled="displayROI"
                  />
                </div>
                <div class="col-7">
                  <label>
                    {{
                      $t(
                        "creditAndCostCalculator.creditCalculator.qualityChecks.types.itemBatteries"
                      )
                    }}
                  </label>
                </div>
                <div class="col-4">
                  <b-form-input
                    type="number"
                    :placeholder="
                      $t(
                        'creditAndCostCalculator.creditCalculator.qualityChecks.placeholderForNumberOfChecks'
                      )
                    "
                    v-model="qualityScores.IBS.qty"
                    :disabled="displayROI"
                    :style="[
                      displayROI
                        ? { background: '#f5f5f5' }
                        : { background: '#fff' }
                    ]"
                  >
                  </b-form-input>
                </div>
              </div>
              <!-- PQ -->
              <div class="row align-items-center mt-2 ml-2">
                <div class="col-1">
                  <input
                    class="text-primary"
                    type="checkbox"
                    v-model="qualityScores.PQ.isEnable"
                    :disabled="displayROI"
                  />
                </div>
                <div class="col-7">
                  <label>
                    {{
                      $t(
                        "creditAndCostCalculator.creditCalculator.qualityChecks.types.projectiveQuestions"
                      )
                    }}
                  </label>
                  <span>
                    <a
                      href="https://help.redem.io/docs/items-qualtiy-checks-questionnaire/projective-questions/general-info-projective-questions/"
                      target="_blank"
                    >
                      <i
                        class="ml-2 bx bxs-info-circle text-info"
                        style="cursor: pointer"
                      ></i>
                    </a>
                  </span>
                </div>
                <div class="col-4">
                  <b-form-input
                    type="number"
                    :placeholder="
                      $t(
                        'creditAndCostCalculator.creditCalculator.qualityChecks.placeholderForNumberOfChecks'
                      )
                    "
                    v-model="qualityScores.PQ.qty"
                    :disabled="displayROI"
                    :style="[
                      displayROI
                        ? { background: '#f5f5f5' }
                        : { background: '#fff' }
                    ]"
                  >
                  </b-form-input>
                </div>
              </div>
            </div>

            <!-- Quality Certificate Level -->
            <div class="row mt-4" v-if="displayQualityCertificate">
              <div class="col-md-12">
                <label>
                  {{
                    $t(
                      "creditAndCostCalculator.creditCalculator.qualityChecks.certificateLevel"
                    )
                  }}:
                  <span class="ml-2">
                    <b-badge style="font-size: 12px" variant="gold">
                      {{ this.availableQualityStandard }}
                    </b-badge>
                  </span>
                </label>
              </div>
            </div>

            <!-- Credit Table -->
            <div class="mt-4">
              <div class="row">
                <div class="col-8">
                  {{
                    $t(
                      "creditAndCostCalculator.creditCalculator.maximumCreditPerRespondent.label"
                    )
                  }}
                  <span>
                    <i
                      v-b-popover.hover="
                        propoverMethodMaximumCreditPerRespondent
                      "
                      class="ml-2 bx bxs-info-circle text-info"
                      style="cursor: pointer"
                    ></i
                  ></span>
                </div>
                <div class="col-4 text-right">
                  {{ creditPerRespondent.toLocaleString("de-DE") }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-8">
                  {{
                    $t(
                      "creditAndCostCalculator.creditCalculator.maximumNumberOfCreditsPerYear"
                    )
                  }}
                </div>
                <div class="col-4 text-right">
                  {{ totalCreditAmount.toLocaleString("de-DE") }}
                </div>
              </div>
              <!-- Display Credit Not Sufficient Error -->
              <div class="mt-4" v-if="displayCreditAmoutNotSufficientError">
                <div class="row">
                  <div class="col-md-12">
                    <b-alert
                      class="mt-3"
                      variant="danger"
                      v-model="displayCreditAmoutNotSufficientError"
                    >
                      {{
                        $t(
                          "creditAndCostCalculator.creditCalculator.lowCreditAmount"
                        )
                      }}
                    </b-alert>
                  </div>
                </div>
              </div>
              <div
                class="row mt-2"
                v-if="!displayCreditAmoutNotSufficientError"
              >
                <div class="col-8">
                  {{
                    $t(
                      "creditAndCostCalculator.creditCalculator.pricePerCredit"
                    )
                  }}
                </div>
                <div class="col-4 text-right">
                  EUR {{ pricePerCredit.toLocaleString("de-DE",{ minimumFractionDigits: 2, maximumFractionDigits: 4 }) }}
                </div>
              </div>
              <hr v-if="!displayCreditAmoutNotSufficientError" />
              <div
                class="row mt-2"
                v-if="!displayCreditAmoutNotSufficientError"
              >
                <div class="col-8">
                  {{
                    $t(
                      "creditAndCostCalculator.creditCalculator.pricePerRespondent"
                    )
                  }}
                </div>
                <div class="col-4 text-right">
                  EUR
                  {{
                    pricePerRespondent.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  }}
                </div>
              </div>
              <div
                class="row mt-2"
                v-if="!displayCreditAmoutNotSufficientError"
              >
                <div class="col-8">
                  {{
                    $t(
                      "creditAndCostCalculator.creditCalculator.totalCostPerYear"
                    )
                  }}
                </div>
                <div class="col-4 text-right">
                  EUR
                  {{
                    totalRedemCostPerYear.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-button
          v-show="
            !displayYourSituation && !displayCreditAmoutNotSufficientError
          "
          variant="success"
          class="btn-block p-2"
          @click="clickDisplayYourSituation()"
        >
          {{ $t("creditAndCostCalculator.creditCalculator.continueButton") }}
        </b-button>
      </div>
    </div>

    <!-- Your Situation -->
    <div class="row justify-content-center" v-if="displayYourSituation">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              {{ $t("creditAndCostCalculator.currentSituation.title") }}
            </div>

            <!-- Number of Projects Per Year -->
            <div class="mt-3 row align-items-center">
              <div class="col-9">
                <label>
                  {{
                    $t(
                      "creditAndCostCalculator.currentSituation.numberofProjectsPerYear.label"
                    )
                  }}
                </label>
              </div>
              <div class="col-3">
                <b-form-input
                  v-model.number="numberOfProjectsPerYear"
                  type="number"
                  :placeholder="
                    $t(
                      'creditAndCostCalculator.currentSituation.numberofProjectsPerYear.placeholder'
                    )
                  "
                  :disabled="displayROI"
                  :style="[
                    displayROI
                      ? { background: '#f5f5f5' }
                      : { background: '#fff' }
                  ]"
                >
                </b-form-input>
              </div>
            </div>

            <!-- time spent per project -->
            <div class="mt-3 row align-items-center">
              <div class="col-9">
                <label>
                  {{
                    $t(
                      "creditAndCostCalculator.currentSituation.timeToSpent.label"
                    )
                  }}
                  <span>
                    <i
                      v-b-popover.hover="propoverMethodTimeSpentForEvaluation"
                      class="ml-1 bx bxs-info-circle text-info"
                      style="cursor: pointer"
                    ></i
                  ></span>
                </label>
              </div>
              <div class="col-3">
                <b-form-input
                  v-model.number="numberOfHoursSpentPerProject"
                  type="number"
                  :placeholder="
                    $t(
                      'creditAndCostCalculator.currentSituation.timeToSpent.placeholder'
                    )
                  "
                  :disabled="displayROI"
                  :style="[
                    displayROI
                      ? { background: '#f5f5f5' }
                      : { background: '#fff' }
                  ]"
                >
                </b-form-input>
              </div>
            </div>

            <!-- estimated cost per hour -->
            <div class="mt-3 row align-items-center">
              <div class="col-9">
                <label>
                  {{
                    $t(
                      "creditAndCostCalculator.currentSituation.costPerHour.label"
                    )
                  }}
                  <span>
                    <i
                      v-b-popover.hover="propoverMethodEstimatedCostPerHour"
                      class="ml-1 bx bxs-info-circle text-info"
                      style="cursor: pointer"
                    ></i
                  ></span>
                </label>
              </div>
              <div class="col-3">
                <b-form-input
                  v-model.number="costPerHour"
                  type="number"
                  :placeholder="
                    $t(
                      'creditAndCostCalculator.currentSituation.costPerHour.placeholder'
                    )
                  "
                  :disabled="displayROI"
                  :style="[
                    displayROI
                      ? { background: '#f5f5f5' }
                      : { background: '#fff' }
                  ]"
                >
                </b-form-input>
              </div>
            </div>
          </div>
        </div>

        <b-button
          v-show="!displayROI"
          variant="success"
          class="btn-block p-2"
          @click="clickDisplayROI()"
          :disabled="
            !numberOfProjectsPerYear ||
            !numberOfHoursSpentPerProject ||
            !costPerHour
          "
        >
          {{
            $t("creditAndCostCalculator.currentSituation.calculateCostButton")
          }}
        </b-button>
      </div>
    </div>

    <!-- ROI -->
    <div class="row justify-content-center">
      <div class="col-lg-4" v-show="displayROI">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="row mb-3 align-content-center">
                <div class="col-7">
                  <h5>
                    <i class="bx bx-money h4 text-warning bx-tada mr-1"></i>
                    {{ $t("creditAndCostCalculator.costSaveSummary.title") }}
                  </h5>
                </div>
                <div class="col-5 text-right">
                  <b-button variant="dark" @click="clickHideROI()" size="sm">
                    {{
                      $t("creditAndCostCalculator.costSaveSummary.editButton")
                    }}
                  </b-button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                {{ $t("creditAndCostCalculator.costSaveSummary.description") }}
              </div>
            </div>

            <table class="table mt-3 mb-5">
              <thead>
                <tr>
                  <th></th>
                  <th class="text-right">
                    {{
                      $t(
                        "creditAndCostCalculator.costSaveSummary.table.projectCost"
                      )
                    }}
                  </th>
                  <th class="text-right">
                    {{
                      $t(
                        "creditAndCostCalculator.costSaveSummary.table.yearlyCost"
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- Current Situation -->
                <tr>
                  <td>
                    <label>
                      {{
                        $t(
                          "creditAndCostCalculator.costSaveSummary.table.currentSituation"
                        )
                      }}
                    </label>
                  </td>
                  <td class="text-right">
                    {{
                      internalCostPerProject.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      totalInternalCostPerYear.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </td>
                </tr>

                <!-- Redem Solution-->
                <tr>
                  <td>
                    <span class="logo-lg">
                      <img
                        src="@/assets/images/Redem-Logo.png"
                        alt
                        height="25"
                      />
                    </span>
                  </td>
                  <td class="text-right">
                    {{
                      redemCostPerProject.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      totalRedemCostPerYear.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                  </td>
                </tr>

                <!-- Cost Saving -->
                <tr>
                  <td>
                    <label>
                      {{
                        $t(
                          "creditAndCostCalculator.costSaveSummary.table.costsaving"
                        )
                      }}
                      <b-badge
                        v-if="costSavingPerYearPercentage > 0"
                        class="ml-2 badge-soft-success"
                        style="font-size: 15px"
                      >
                        <strong>
                          {{
                            costSavingPerYearPercentage.toLocaleString(
                              "de-DE",
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                              }
                            )
                          }}
                          %
                        </strong>
                      </b-badge>
                    </label>
                  </td>
                  <td class="text-right">
                    <strong v-if="costSavingPerProject > 0">
                      {{
                        costSavingPerProject.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }}
                    </strong>
                    <span v-else> 0 </span>
                  </td>
                  <td class="text-right">
                    <strong v-if="costSavingPerYear > 0">
                      {{
                        costSavingPerYear.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                      }}
                    </strong>
                    <span v-else> 0 </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="card-title mt-5">
              <div class="row">
                <div class="col-12">
                  <h5>
                    <i class="bx bx-diamond h4 text-success bx-tada mr-1"></i>
                    {{
                      $t(
                        "creditAndCostCalculator.costSaveSummary.benefits.title"
                      )
                    }}
                  </h5>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <ul>
                  <li class="mb-2">
                    <strong>
                      {{
                        $t(
                          "creditAndCostCalculator.costSaveSummary.benefits.point1.bold"
                        )
                      }}
                    </strong>
                    {{
                      $t(
                        "creditAndCostCalculator.costSaveSummary.benefits.point1.normal"
                      )
                    }}
                  </li>
                  <li class="mb-2">
                    <strong>
                      {{
                        $t(
                          "creditAndCostCalculator.costSaveSummary.benefits.point2.bold"
                        )
                      }}
                    </strong>
                    {{
                      $t(
                        "creditAndCostCalculator.costSaveSummary.benefits.point2.normal"
                      )
                    }}
                  </li>
                  <li class="mb-2">
                    <strong>
                      {{
                        $t(
                          "creditAndCostCalculator.costSaveSummary.benefits.point3.bold"
                        )
                      }}
                    </strong>
                    {{
                      $t(
                        "creditAndCostCalculator.costSaveSummary.benefits.point3.normal"
                      )
                    }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <b-button
          v-show="displayROI"
          variant="primary"
          class="btn-block p-2"
          @click="clickMailToButton()"
        >
          {{$t("creditAndCostCalculator.requestOfferButton")}}
        </b-button>
      </div>
    </div>

    <!-- Leagual Sentence -->
    <div class="row justify-content-center text-center mt-3">
      <div class="col-md-4">
        {{ $t("creditAndCostCalculator.legalSentence") }}
      </div>
    </div>

    <!-- language Selector -->
    <div class="mt-3 text-center">
      <span
        class="mr-2"
        @click="setLanguage(languages[0].language)"
        style="cursor: pointer"
      >
        <img class="mr-1" :src="languages[0].flag" height="10" />
        {{ languages[0].title }}
      </span>
      &bull;
      <span
        class="ml-2"
        @click="setLanguage(languages[1].language)"
        style="cursor: pointer"
      >
        <img class="mr-1" :src="languages[1].flag" height="10" />
        {{ languages[1].title }}
      </span>
    </div>
  </div>
</template>

<style scoped>
table,
th,
td {
  border: 1px solid #f5f5f5;
}
</style>